.certificate
{
    display: flex;
    justify-content: center;
    height: 50rem;
    padding-top: 4rem;
}

@media (max-width: 991px)
{
    .certificate img
    {
        width: 100%;
    }
}